import React from 'react'
// import Logo from "./assets/spinner.gif"
function Load() {
  return (

  <div style={{ margin: "auto",
    width: "50%",
    textAlign: "center",
    // border: "3px solid green",
    padding: "100px 0"}}>
  <img 
  height='330px'
  src="https://alahdeen.com/pro/image/aaa.gif" />
  </div>

  )
}

export default Load