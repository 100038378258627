import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from 'firebase/app';

function Login() {
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
    } else {
      setPermission(Notification.permission);
    }

    as()
  
}, []);

const handleClick = (id) => {
  if (permission === 'granted') {
    SendWelcome(id);
  } else if (permission === 'denied') {
    alert('Permission denied.')
    console.log('Permission denied.');
  } else {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Permission granted.');
        SendWelcome(id);
      }
    });
  }
};

  const firebaseConfig = {
    apiKey: "AIzaSyCNThPGDzsQTuxrGZYBuG-H8yCK4CTg1Jo",
    authDomain: "alahdeen-24173.firebaseapp.com",
    databaseURL: "https://alahdeen-24173-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "alahdeen-24173",
    storageBucket: "alahdeen-24173.appspot.com",
    messagingSenderId: "298890559548",
    appId: "1:298890559548:web:6fda9c54ae5e295d868339",
    measurementId: "G-6Q2NLSNF9T"
  };

  const [token, setToken] = useState(''); 
  const [uid, setUid] = useState(''); 
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  const [email, setEmail] = useState("");
  
  const [pass, setPass] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [error, seterror] = useState("");

  const loginNow = async (e) => {
    e.preventDefault();
console.log("email :",email," : pass ",pass,"token -",token)
    const data = new FormData();
    data.append("email", email);
    data.append("pass", pass);
    data.append("status", "login");
    data.append("did", "web");
    data.append("dtype", "web");
    data.append("token", token);

    axios({
      method: "post",
      url: "/pro/auth/login.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
console.log(s.data)
         if (s.data[0].msg === "pass") {

          localStorage.setItem("USER", JSON.stringify(s.data));
          setUid(s.data[0].user)
          SendWelcome(s.data[0].user);
          handleClick(s.data[0].user)
          navigate("/");
          console.log(s.data)
        } else if (s.data[0].msg === "block") {
          alert("Sorry! Your account is Disabled. Please, contact Admin");
        }else if (s.data[0].msg === "otp") {
          navigate("/otp");
        } else {
          alert("Invalid Phone or Password! Please try again.");
        }
      })
      .catch((s) => {
        console.log("here catch ", s);
      });
  };
 
  const SendWelcome = async (uid) => {
  

    const data = new FormData();
    data.append("userid", uid);
    data.append("typeid", "1");
    data.append("title", "New Login Activity ");
    data.append("body", "Your alahdeen account is logged in from Web Browser");
    data.append("status", "1");
    data.append("android", "Home");
    data.append("ios", "Home");
    data.append("web", "Homepage");
    data.append("senderid", uid);


    axios({
      method: "post",
      url: "/pro/auth/sendnotification.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {

      navigate('/')
      })
      .catch((s) => {
        console.log("here catch ", s);
        navigate('/')
      });
  };

  const as=()=>{
    getToken(messaging, { vapidKey: "BHCoZmVKjdsI3vrieJcu2h0f5_dczoimUN6xMct50s5eC3wdOF4h_BfP8jVUVaZwzfQxyzcAwcp0oV5ws1AXrX0" })
    .then((currentToken) => {
      if (currentToken) {
  
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
        setToken(currentToken)
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }
  
  const text_type=(input)=>{
    // Regular expression to check if the string contains only alphabetic characters
  const regex = /^[A-Za-z\s]+$/;

  // Test the input against the regular expression
  return regex.test(input);

  }


  return (
    <body
      style={{
       backgroundImage: `url("https://alahdeen.com/pro/image/test.jpg")`,
       //backgroundColor: "dodgerblue",
        // height: "100vh",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div >
        <div >
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="login-content" style={{marginTop:"-10px"}}>
                <div style={{ opacity:0.9}} className="login-form">
                  <center>
                    <img
                    
                      onClick={() => {
                        navigate("/");
                      }}
                      style={{
                      
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      src="assets/images/logob.png"
                      height={100}
                    />
                  </center>
                  <h4 style={{ marginTop: "2px" }}>Sign In</h4>

                  <div  className= {error =="phone"?"form-group col-12 has-error":"form-group col-12"} >
                  <div class="col-auto">
                  <label>Phone No.</label>
                  <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroupPrepend"> +92 </span>
        </div>
        <input type="text"  placeholder="Phone No." aria-describedby="inputGroupPrepend" className="form-control" value={email}  
         onChange={(v) => {
         if(v.target.value.length<11)
         {
          if( !text_type(v.target.value))
            {
              setEmail(v.target.value);
              
            }

          
         }else {
          seterror("phone")
          
         }
          }} />
          
         </div>
</div>

     
                  
                  </div>
                  <div  className= {error =="phone"?"form-group col-12 has-error":"form-group col-12"} >
                  <div class="col-auto">
                  `<label>Password</label>
                  <div class="input-group">
       
        <input type={show ? "text" : "password"}   placeholder="Password" aria-describedby="inputGroupPrepend" className="form-control"   onChange={(v) => {
         setPass(v.target.value);
                        

                      }} />
                       
       
      </div>
</div>

     
                  
                  </div>

                  {/* <div className="form-group">
                    <label>Phone </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      placeholder="Phone"
                      onChange={(v) => {
                        if(v.target.value.length<11)
                        {
                          setEmail(v.target.value);
                        
                        }else {
                          alert("phone number invalid")
                        }
                        
                      }}
                    />
                  </div> */}


                 
                  <div className="checkbox">
                    <label
                      onClick={() => {
                        setShow(!show);
                      }}
                    >
                      {show ? (
                        <>
                          {" "}
                          <input type="checkbox" checked /> Show Password
                        </>
                      ) : (
                        <>
                          {" "}
                          <input type="checkbox" unchecked /> Show Password
                        </>
                      )}
                    </label>
                    <label className="pull-right">
                      <a href="/forgotten">Forgotten Password?</a>
                    </label>
                  </div>
                  <button
                    onClick={(e) => {
                    

                      if(email.length==10)
                      {

                        
                        if(token.length=="")
                        {
                          loginNow(e);
                          console.log("abc")
                        }else{
                          loginNow(e);
                          console.log("abc done")
                        }
                      }else{
                        alert("Phone Number Invalid!")
                        
                      }
                    }}
                    className="btn btn-primary btn-flat m-b-30 m-t-30"
                  >
                    Sign in
                  </button>
                  <div className="register-link m-t-15 text-center">
                    <p>
                      Don't have account ? <a href="signup"> Sign Up</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Login;
