import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function Nav() {
  const [lead, setLead] = useState(false);
  const [bkhata, setBkhata] = useState(false);
  // const [bwallet, setBwallet] = useState(false);

  const [bwarehouse, setBwarehouse] = useState(false);

  // const [blogistics, setBlogistics] = useState(false);
  // const [bnewbusiness, setBnewbusiness] = useState(false);
  // const [bad, setBad] = useState(false);
  // const [boffice, setBoffice] = useState(false);
  // const [binventory, setBinventory] = useState(false);
  const [userLogin, setUser] = useState("");
  const [darw, setDarw] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    setUser(v);
    if (v === null) {
      navigate("/login");
     
    }
  }, []);
  // useEffect(() => {
  //   const v = JSON.parse(localStorage.getItem("USER"));
  //   if (v === null) {
  //     navigate("/login");
  //   }
  // }, []);

  // const loginNow = async (e) => {
  //   const data = new FormData();
  //   data.append("key", e);

  //   data.append("status", "key");

  //   axios({
  //     method: "post",
  //     url: "/pro/login.php",
  //     data: data,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  //     .then((s) => {
  //       // console.log(s.data);
  //       localStorage.setItem("USER", JSON.stringify(s.data));
  //       setUser(s.data);

  //       if (s.data[0].msg === "pass") {
  //         localStorage.setItem("USER", JSON.stringify(s.data));
  //         navigate("/");
  //       } else if (s.data[0].msg === "block") {
  //         alert("Sorry! Your account is Disabled. Please, contact Admin");
  //       } else {
  //         alert("Invalid Email or Password! Please try again.");
  //       }
  //     })
  //     .catch((s) => {
  //       // console.log("here catch ", s);
  //     });
  // };
  return (
    <>
      {darw ? (
        <>
          {" "}
          <div className="sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures">
            <div className="nano">
              <div className="nano-content">
                <div className="logo">
                  <span>
                    <img
                      onClick={() => {
                        //navigate('/https://alahdeen.com/');
                        // window.location.replace('https://alahdeen.com/');
                        navigate("/");
                      }}
                      style={{ marginTop: "20px", cursor: "pointer" }}
                      height={100}
                      src="assets/images/logo.png"
                      alt=""
                    />
                  </span>
                </div>
                <ul>
                  <li
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{ color: "white", cursor: "pointer" }}
                    className=" my-3 mx-3"
                  >
                    <span className="sidebar-sub-toggle">
                      <i className="ti-home"></i> Main Website{" "}
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/buyer");
                    }}
                    style={{ color: "white", cursor: "pointer" }}
                    className=" my-3 mx-3"
                  >
                    <span className="sidebar-sub-toggle">
                      <i className="ti-home"></i> Dashboard
                    </span>
                  </li>

                  <li
                    onClick={() => {
                      setLead(!lead);
                    }}
                  >
                    <a 
                      
                     className="sidebar-sub-toggle">
                      <i className="ti-layout-grid4-alt"></i> Manage Leads{" "}
                      <span className="sidebar-collapse-icon ti-angle-down"></span>
                    </a>
                  </li>
                  <li>
                    {lead ? (
                      <>
                        <Link to="/product-buy-request">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-wallet"></i> Buying Requests
                          </li>
                        </Link>
                      
                      </>
                    ) : (
                      <></>
                    )}
                  </li>

                  {/* products List start*/}

                  {/* Warehouse  */}
{/* Warehouse Hidden Start #1122 */}
                  <li
                    onClick={() => {
                      setBwarehouse(!bwarehouse);
                    }}
                  >
                    <a className="sidebar-sub-toggle">
                      <i className="ti-layout-grid4-alt"></i> Warehouse{" "}
                      <span className="sidebar-collapse-icon ti-angle-down"></span>
                    </a>
                  </li>
                  <li>
                    {bwarehouse ? (
                      <>
                        <Link to="/requestedbooking">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-wallet"></i>Requested Bookings
                          </li>
                        </Link>

                        <Link to="/bookinginvoices">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-info-alt"></i> Booked Warehouses
                          </li>
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </li>
{/* Warehouse Hidden End #1122 */}
                  {/* Warehouse  */}

                  <li
                    onClick={() => {
                      setBkhata(!bkhata);
                    }}
                  >
                    <a className="sidebar-sub-toggle">
                      <i className="ti-layout-grid4-alt"></i> Khata{" "}
                      <span className="sidebar-collapse-icon ti-angle-down"></span>
                    </a>
                  </li>
                  <li>
                    {bkhata ? (
                      <>
                         <Link to="/buyerclient">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-wallet"></i>Manage Clients
                          </li>
                        </Link>
                      
                      
                        <Link to="/buyer_invoice">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-wallet"></i>Manage Invoices
                          </li>
                        </Link>
                     
                        <Link to="/purchaseorder">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-info-alt"></i>Purchase Order
                          </li>
                        </Link>



                        <Link to="/buyerchallan">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-wallet"></i>Delivery Challan
                          </li>
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </li>

                  <li
                    onClick={() => {
                      localStorage.removeItem("USER");
                      navigate("/login");
                    }}
                  >
                    <a>
                      <i className="ti-close"></i> Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <!-- /# sidebar --> */}

      <div className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 ">
              <div
                onClick={() => {
                  setDarw(!darw);
                }}
                className="float-left"
              >
                <div className="hamburger sidebar-toggle">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </div>
             
              <div className="float-right">
              <div className="header-icon">
                {" "}
                <Link to="/seller">
                  <p
                    style={{
                      marginLeft: "83px",
                    paddingLeft:"11px",
                    paddingTop:"2px",
                    paddingBottom:"2px",
                    paddingRight:"11px",
                    backgroundColor:"black",
                     border:"1px solid black",
                     borderRadius:"20px",

                      color: "white",
                    }}
                  >
                    Switch to Seller
                  </p>
                </Link>
              </div>
                <div className="dropdown dib"></div>
                
                  <a href="/chat" >
                  <div className="header-icon"  >
                    
                      <i style={{ color: "black" }} className="ti-email"></i>
                    
                  </div>
                  </a>
               

                <span className="user-avatar text-uppercase"  style={{ marginRight: "25px", }}
                >
                  {userLogin.length > 0 ? <>{userLogin[0].name}</> : <></>}
                </span>
            

                {/* <div className="dropdown dib">
                  <div className="header-icon" data-toggle="dropdown">
                    <span className="user-avatar text-uppercase"></span>
                    <div className="drop-down dropdown-profile dropdown-menu dropdown-menu-right">
                      <div>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="ti-user"></i>
                              <span>Personal Profile</span>
                            </a>
                          </li>

                          <li>
                            <a href="#">
                              <i className="ti-email"></i>
                              <span>Business Profile</span>
                            </a>
                          </li>

                          <li>
                            <a href="#">
                              <i className="ti-settings"></i>
                              <span>Director Profile</span>
                            </a>
                          </li>

                          <li>
                            <a href="/login">
                              <i className="ti-lock"></i>
                              <span>Certificate & Awards</span>
                            </a>
                          </li>
                          <li
                            onClick={() => {
                              localStorage.removeItem("USER");
                              navigate("/login");
                            }}
                          >
                            <a href="#">
                              <i className="ti-power-off"></i>
                              <span>Logout</span>
                            </a>
                          </li>

                          <li
                            onClick={() => {
                              alert("hi");
                              localStorage.removeItem("USER");
                              navigate("/login");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <a href="/login">
                              <i className="ti-close"></i> Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nav;
