import react from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import Load from "../components/landingpage/load"
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";

function Subcat() {
  
  const { name } = useParams();
  const navigate = useNavigate();
  const [allcats, setAllcats] = useState([]);
  const [load, setLoad] = useState([]);
  
  const [title, setTitle] = useState([]);
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
  useEffect(() => {
    const a =localStorage.getItem("title")
    
    getServercats();
  }, []);

  const getServercats = async () => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    
     setTitle(params.get("s"));

    
      const data = new FormData();



      data.append("id", id);
      
  
      axios({
        method: "post",
        url: "/mainsite/suball.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((s) => {
          setAllcats(s.data);
          console.log("new page of sub cat", s.data);
        
        })
        .catch((s) => {
          // console.log("here catch =>>", s);
        });
      
    
     

  };

  const getServercatsUpdate = async (id) => {
    const data = new FormData();

    data.append("id", id);

    axios({
      method: "post",

      url: "/mainsite/suball.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
        // console.log(s.data);
      })
      .catch((s) => {
        // console.log("here catch", s);
      });
  };
  return (
<>
<Header/>
<main className="main">
  {/* <div className="section-box">
    <div className="breadcrumbs-div">
      <div className="container">
        <ul className="breadcrumb">
          <li>
            <a className="font-xs color-gray-1000" href="index.html">
              Home
            </a>
          </li>
          <li>
            <a className="font-xs color-gray-500" href="shop-grid.html">
              Electronics
            </a>
          </li>
          <li>
            <a className="font-xs color-gray-500" href="shop-grid.html">
              Cell phone
            </a>
          </li>
          <li>
            <a className="font-xs color-graProducts Filtey-500" href="shop-grid.html">
              Accessories
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div> */}
  <div className="section-box shop-template mt-30">
    <div className="container">
      <div className="row">
        <div className="col-lg-9 order-first order-lg-last">
          <div className="banner-ads-top mb-30">
            <a href="shop-single-product-3.html">
              <img src="assetsss/imgs/page/shop/banner.png" alt="Ecom" />
            </a>
          </div>
          {/* <div className="box-filters mt-0 pb-5 border-bottom">
            <div className="row">
              <div className="col-xl-2 col-lg-3 mb-10 text-lg-start text-center">
                <a
                  className="btn btn-filter font-sm color-brand-3 font-medium"
                  href="#ModalFiltersForm"
                  data-bs-toggle="modal"
                >
                  All Fillters
                </a>
              </div>
              <div className="col-xl-10 col-lg-9 mb-10 text-lg-end text-center">
                <span className="font-sm color-gray-900 font-medium border-1-right span">
                  Showing 1–16 of 17 results
                </span>
                <div className="d-inline-block">
                  <span className="font-sm color-gray-500 font-medium">
                    Sort by:
                  </span>
                  <div className="dropdown dropdown-sort border-1-right">
                    <button
                      className="btn dropdown-toggle font-sm color-gray-900 font-medium"
                      id="dropdownSort"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Latest products
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-light"
                      aria-labelledby="dropdownSort"
                      style={{ margin: 0 }}
                    >
                      <li>
                        <a className="dropdown-item active" href="#">
                          Latest products
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Oldest products
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Comments products
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-inline-block">
                  <span className="font-sm color-gray-500 font-medium">
                    Show
                  </span>
                  <div className="dropdown dropdown-sort border-1-right">
                    <button
                      className="btn dropdown-toggle font-sm color-gray-900 font-medium"
                      id="dropdownSort2"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-display="static"
                    >
                      <span>30 items</span>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-light"
                      aria-labelledby="dropdownSort2"
                    >
                      <li>
                        <a className="dropdown-item active" href="#">
                          30 items
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          50 items
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          100 items
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-inline-block">
                  <a
                    className="view-type-grid mr-5 active"
                    href="shop-grid.html"
                  />
                  <a className="view-type-list" href="shop-list.html" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="row mt-20">

          {allcats.length > 1
                ? allcats.map((v, i) => (
                    <>
                   

<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                 
                  <div className="image-box">
                   
                    <a  href={"sub?id=" + v.id + "&s=" + v.title}>
                      <img
                       src={v.img}
                        alt={v.title}
                      />
                    </a>
                  </div>
                  <div className="info-right">
                  
                    <a
                      className="color-brand-3 font-sm-bold"
                      href={"sub?id=" + v.id + "&s=" + v.title}
                    >
                    {v.title}
                    </a>
             
                
                  </div>
                </div>
              </div>
            </div>

                    </>
                  ))
                :   <div className="row row-sm">
                {allcats.length == 1
                  ? allcats[0].child.map((v, i) => (
                      <>
                      {/* new  start*/}
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                 
                  <div className="image-box">
                   
                    <a href={"/product?id=" + v.id + "&s=" + v.name}>
                      <img
                      src={v.img}
                        alt={v.name}
                      />
                    </a>
                  </div>
                  <div className="info-right">
                  
                    <a
                      className="color-brand-3 font-sm-bold"
                      href={"/product?id=" + v.id + "&s=" + v.name}
                    >
                    {v.name}
                    </a>
             
                
                  </div>
                </div>
              </div>
            </div>
                      {/* new end */}
                       
                      </>
                    ))
                  : <Load/>}
              </div>}


     
          </div>
          {/* <nav>
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link page-prev" href="#" />
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link active" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  4
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  5
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  6
                </a>
              </li>
              <li className="page-item">
                <a className="page-link page-next" href="#" />
              </li>
            </ul>
          </nav> */} 
        </div>
        <div className="col-lg-3 order-last order-lg-first">
          <div className="sidebar-border mb-0">
            <div className="sidebar-head">
              <h6 className="color-gray-900">Product Categories</h6>
            </div>
            <div className="sidebar-content">
              <ul className="list-nav-arrow">
                {/* 1 */}
              <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Apparel & Garments"
                            );
                            window.location.assign(
                              "/sub?id=7&s=Apparel%20&%20Garments"
                            );
                          }}
                        >
                          Apparel & Garments
                        </a>
                      </li>
                      {/* 2 */}
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem("title", " Food & Beverages");
                            window.location.assign(
                              "/sub?id=3&s=Food%20&%20Beverages"
                            );
                          }}
                        >
                          Food & Beverages
                        </a>
                      </li>
                      {/* 3 */}
                      
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Electronics & Electrical"
                            );
                            window.location.assign(
                              "/sub?id=8&s=Electronics%20&%20Electrical"
                            );
                          }}
                        >
                          Electronics & Electrical
                        </a>
                      </li>
                      {/* 4 */}
                      {/* 5 */}
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Building Construction"
                            );
                            window.location.assign(
                              "/sub?id=6&s=Building%20&%20Construction"
                            );
                          }}
                        >
                          Building Construction
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Building Construction"
                            );
                            window.location.assign(
                              "/sub?id=27&s=Computer & IT Solutions"
                            );
                          }}
                        >
                          Computer & IT Solutions
                        </a>
                      </li>
                      {/* 6 */}

                      <li  style={{ cursor: "pointer" }}>
                        <a
                       
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Drugs & Pharmaceuticals"
                            );
                            window.location.assign(
                              "/sub?id=1&s=Drugs%20&%20Pharmaceuticals"
                            );
                          }}
                        >
                          Drugs & Pharmaceuticals
                        </a>
                      </li>
{/* 7 */}
             

                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Industrial Plants & Machinery"
                            );
                            window.location.assign(
                              "/sub?id=4&s=Industrial%20Plants%20&%20Machinery"
                            );
                          }}
                        >
                          Industrial Plants & Machinery
                        </a>
                      </li>
                    
{/* 9 */}
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Hospital & Diagnostics"
                            );
                            window.location.assign(
                              "/sub?id=2&s=Hospital%20&%20Diagnostics"
                            );
                          }}
                        >
                          Hospital & Diagnostics
                        </a>
                      </li>
                      <li style={{ cursor: "pointer", color:'blue' }}>
                        <a
                          onClick={() => {
                           navigate("/main")
                          }}
                        >
                        All Categories
                        </a>
                      </li>
                   
                     
                    </ul>
               
              
            </div>
          </div>
       
        
        
        </div>
       
      </div>
    </div>
  </div>
  <section className="section-box mt-90 mb-50">
    <div className="container">
      <ul className="list-col-5">
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/delivery.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Free Delivery</h5>
              <p className="font-sm color-gray-500">From all orders over $10</p>
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/support.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
              <p className="font-sm color-gray-500">Shop with an expert</p>
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/voucher.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
              <p className="font-sm color-gray-500">Refer a friend</p>
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/return.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">
                Return &amp; Refund
              </h5>
              <p className="font-sm color-gray-500">Free return over $200</p>
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/secure.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Secure payment</h5>
              <p className="font-sm color-gray-500">100% Protected</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <section className="section-box box-newsletter">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-7 col-sm-12">
          <h3 className="color-white">
            Subscrible &amp; Get <span className="color-warning">10%</span>{" "}
            Discount
          </h3>
          <p className="font-lg color-white">
            Get E-mail updates about our latest shop and{" "}
            <span className="font-lg-bold">special offers.</span>
          </p>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-12">
          <div className="box-form-newsletter mt-15">
            <form className="form-newsletter">
              <input
                className="input-newsletter font-xs"
                defaultValue=""
                placeholder="Your email Address"
              />
              <button className="btn btn-brand-2">Sign Up</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div
    className="modal fade"
    id="ModalFiltersForm"
    tabIndex={-1}
    aria-hidden="true"
    style={{ display: "none" }}
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content apply-job-form">
        <div className="modal-header">
          <h5 className="modal-title color-gray-1000 filters-icon">
            Addvance Fillters
          </h5>
          <button
            className="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body p-30">
          <div className="row">
            <div className="col-w-1">
              <h6 className="color-gray-900 mb-0">Brands</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox" defaultChecked="checked" />
                    <span className="text-small">Apple</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Samsung</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Baseus</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Remax</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Handtown</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Elecom</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Razer</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Auto Focus</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Nillkin</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Logitech</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">ChromeBook</span>
                    <span className="checkmark" />
                  </label>
                </li>
              </ul>
            </div>
            <div className="col-w-1">
              <h6 className="color-gray-900 mb-0">Special offers</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">On sale</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" defaultChecked="checked" />
                    <span className="text-small">FREE shipping</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Big deals</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Shop Mall</span>
                    <span className="checkmark" />
                  </label>
                </li>
              </ul>
              <h6 className="color-gray-900 mb-0 mt-40">Ready to ship in</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">1 business day</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" defaultChecked="checked" />
                    <span className="text-small">1–3 business days</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">in 1 week</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Shipping now</span>
                    <span className="checkmark" />
                  </label>
                </li>
              </ul>
            </div>
            <div className="col-w-1">
              <h6 className="color-gray-900 mb-0">Ordering options</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Accepts gift cards</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Customizable</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" defaultChecked="checked" />
                    <span className="text-small">Can be gift-wrapped</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Installment 0%</span>
                    <span className="checkmark" />
                  </label>
                </li>
              </ul>
              <h6 className="color-gray-900 mb-0 mt-40">Rating</h6>
              <ul className="list-checkbox">
                <li className="mb-5">
                  <a href="#">
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                      (5 stars)
                    </span>
                  </a>
                </li>
                <li className="mb-5">
                  <a href="#">
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                      (4 stars)
                    </span>
                  </a>
                </li>
                <li className="mb-5">
                  <a href="#">
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                      (3 stars)
                    </span>
                  </a>
                </li>
                <li className="mb-5">
                  <a href="#">
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                      (2 stars)
                    </span>
                  </a>
                </li>
                <li className="mb-5">
                  <a href="#">
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <img
                      src="assetsss/imgs/template/icons/star-gray.svg"
                      alt="Ecom"
                    />
                    <span className="ml-10 font-xs color-gray-500 d-inline-block align-top">
                      (1 star)
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-w-2">
              <h6 className="color-gray-900 mb-0">Material</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Nylon (8)</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Tempered Glass (5)</span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" defaultChecked="checked" />
                    <span className="text-small">
                      Liquid Silicone Rubber (5)
                    </span>
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" />
                    <span className="text-small">Aluminium Alloy (3)</span>
                    <span className="checkmark" />
                  </label>
                </li>
              </ul>
              <h6 className="color-gray-900 mb-20 mt-40">Product tags</h6>
              <div>
                <a className="btn btn-border mr-5" href="#">
                  Games
                </a>
                <a className="btn btn-border mr-5" href="#">
                  Electronics
                </a>
                <a className="btn btn-border mr-5" href="#">
                  Video
                </a>
                <a className="btn btn-border mr-5" href="#">
                  Cellphone
                </a>
                <a className="btn btn-border mr-5" href="#">
                  Indoor
                </a>
                <a className="btn btn-border mr-5" href="#">
                  VGA Card
                </a>
                <a className="btn btn-border mr-5" href="#">
                  USB
                </a>
                <a className="btn btn-border mr-5" href="#">
                  Lightning
                </a>
                <a className="btn btn-border mr-5" href="#">
                  Camera
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-start pl-30">
          <a className="btn btn-buy w-auto" href="#">
            Apply Fillter
          </a>
          <a className="btn font-sm-bold color-gray-500" href="#">
            Reset Fillter
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    className="modal fade"
    id="ModalQuickview"
    tabIndex={-1}
    aria-hidden="true"
    style={{ display: "none" }}
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content apply-job-form">
        <button
          className="btn-close"
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-body p-30">
          <div className="row">
            <div className="col-lg-6">
              <div className="gallery-image">
                <div className="galleries-2">
                  <div className="detail-gallery">
                    <div className="product-image-slider-2">
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-1.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-2.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-3.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-4.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-5.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-6.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-7.jpg"
                          alt="product image"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="slider-nav-thumbnails-2">
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-1.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-2.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-3.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-4.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-5.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-6.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-7.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-tags">
                <div className="d-inline-block mr-25">
                  <span className="font-sm font-medium color-gray-900">
                    Category:
                  </span>
                  <a className="link" href="#">
                    Smartphones
                  </a>
                </div>
                <div className="d-inline-block">
                  <span className="font-sm font-medium color-gray-900">
                    Tags:
                  </span>
                  <a className="link" href="#">
                    Blue
                  </a>
                  ,
                  <a className="link" href="#">
                    Smartphone
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="product-info">
                <h5 className="mb-15">
                  SAMSUNG Galaxy S22 Ultra, 8K Camera &amp; Video, Brightest
                  Display Screen, S Pen Pro
                </h5>
                <div className="info-by">
                  <span className="bytext color-gray-500 font-xs font-medium">
                    by
                  </span>
                  <a
                    className="byAUthor color-gray-900 font-xs font-medium"
                    href="shop-vendor-list.html"
                  >
                    {" "}
                    Ecom Tech
                  </a>
                  <div className="rating d-inline-block">
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <span className="font-xs color-gray-500 font-medium">
                      {" "}
                      (65 reviews)
                    </span>
                  </div>
                </div>
                <div className="border-bottom pt-10 mb-20" />
                <div className="box-product-price">
                  <h3 className="color-brand-3 price-main d-inline-block mr-10">
                    $2856.3
                  </h3>
                  <span className="color-gray-500 price-line font-xl line-througt">
                    $3225.6
                  </span>
                </div>
                <div className="product-description mt-10 color-gray-900">
                  <ul className="list-dot">
                    <li>8k super steady video</li>
                    <li>Nightography plus portait mode</li>
                    <li>50mp photo resolution plus bright display</li>
                    <li>Adaptive color contrast</li>
                    <li>premium design &amp; craftmanship</li>
                    <li>Long lasting battery plus fast charging</li>
                  </ul>
                </div>
                <div className="box-product-color mt-10">
                  <p className="font-sm color-gray-900">
                    Color:
                    <span className="color-brand-2 nameColor">Pink Gold</span>
                  </p>
                  <ul className="list-colors">
                    <li className="disabled">
                      <img
                        src="assetsss/imgs/page/product/img-gallery-1.jpg"
                        alt="Ecom"
                        title="Pink"
                      />
                    </li>
                    <li>
                      <img
                        src="assetsss/imgs/page/product/img-gallery-2.jpg"
                        alt="Ecom"
                        title="Gold"
                      />
                    </li>
                    <li>
                      <img
                        src="assetsss/imgs/page/product/img-gallery-3.jpg"
                        alt="Ecom"
                        title="Pink Gold"
                      />
                    </li>
                    <li>
                      <img
                        src="assetsss/imgs/page/product/img-gallery-4.jpg"
                        alt="Ecom"
                        title="Silver"
                      />
                    </li>
                    <li className="active">
                      <img
                        src="assetsss/imgs/page/product/img-gallery-5.jpg"
                        alt="Ecom"
                        title="Pink Gold"
                      />
                    </li>
                    <li className="disabled">
                      <img
                        src="assetsss/imgs/page/product/img-gallery-6.jpg"
                        alt="Ecom"
                        title="Black"
                      />
                    </li>
                    <li className="disabled">
                      <img
                        src="assetsss/imgs/page/product/img-gallery-7.jpg"
                        alt="Ecom"
                        title="Red"
                      />
                    </li>
                  </ul>
                </div>
                <div className="box-product-style-size mt-10">
                  <div className="row">
                    <div className="col-lg-12 mb-10">
                      <p className="font-sm color-gray-900">
                        Style:
                        <span className="color-brand-2 nameStyle">S22</span>
                      </p>
                      <ul className="list-styles">
                        <li className="disabled" title="S22 Ultra">
                          S22 Ultra
                        </li>
                        <li className="active" title="S22">
                          S22
                        </li>
                        <li title="S22 + Standing Cover">
                          S22 + Standing Cover
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-12 mb-10">
                      <p className="font-sm color-gray-900">
                        Size:
                        <span className="color-brand-2 nameSize">512GB</span>
                      </p>
                      <ul className="list-sizes">
                        <li className="disabled" title="1GB">
                          1GB
                        </li>
                        <li className="active" title="512 GB">
                          512 GB
                        </li>
                        <li title="256 GB">256 GB</li>
                        <li title="128 GB">128 GB</li>
                        <li className="disabled" title="64GB">
                          64GB
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="buy-product mt-5">
                  <p className="font-sm mb-10">Quantity</p>
                  <div className="box-quantity">
                    <div className="input-quantity">
                      <input
                        className="font-xl color-brand-3"
                        type="text"
                        defaultValue={1}
                      />
                      <span className="minus-cart" />
                      <span className="plus-cart" />
                    </div>
                    <div className="button-buy">
                      <a className="btn btn-cart" href="shop-cart.html">
                        Add to cart
                      </a>
                      <a className="btn btn-buy" href="shop-checkout.html">
                        Buy now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<Footer/>
</>
  )
}

export default Subcat