import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";


function Nav() {


  const [darw, setDarw] = useState(true);
  const [userLogin, setUser] = useState([]);
  const [userType, setUserType] = useState('');

  const [nav, setNav] = useState([{
    ewallet:false ,binventory:false ,subscription:false ,product:false ,catalog:false ,
    warehouse:false ,khata:false ,manageleads:false ,mattersheet:false ,logistics:false ,
    logistics_driver:false ,newbusiness:false ,ad:false ,office:false ,darw:false
  }]);

  const navigate = useNavigate();


  const changeNavList = (i, changes) => {
    const a=0;
    const oldArr = [...nav];
    const oldOb = oldArr[a];
    oldArr[a] = { ...oldOb, ...changes };
    localStorage.setItem("nav",JSON.stringify(oldArr))
    setNav(oldArr);
    
  }

  useEffect(() => {

const as=JSON.parse(localStorage.getItem("nav"))
if(as==null)
{
localStorage.setItem("nav",JSON.stringify(nav))

}else{
  setNav(as)
}



    const v = JSON.parse(localStorage.getItem("USER"));
    setUser(v);
   console.log("")
    setUserType(v[0].roll)

    if (v === null) {
      navigate("/login");
     
    }
  }, []);

 

  return (
    <>
      

      {darw ? (
        <>
          {" "}
          <div className="sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures">
            <div className="nano">
              <div className="nano-content">
                <div className="logo">
                  <span>
                    <img
                      onClick={() => {
                        navigate("/");
                      }}
                      style={{ marginTop: "20px", cursor: "pointer" }}
                      height={100}
                      src="assets/images/logo.png"
                      alt=""
                    />
                  </span>
                </div>
                <ul>
                  <a
                    href="/"
              
                  >
                    <li style={{ color: "white", cursor: "pointer" }}  className=" my-3 mx-3">
                      <span className="sidebar-sub-toggle">
                        <i className="ti-home"></i> Main Website 
                      </span>
                    </li>
                  </a>


                  <a
                    href="/seller"
              
                  >
                    <li
                      style={{ color: "white", cursor: "pointer" }}
                      className=" my-3 mx-3"
                    >
                      <span className="sidebar-sub-toggle">
                      <i className="fa fa-window-restore" aria-hidden="true"></i> Dashboard
                      </span>
                    </li>
                  </a>
                  <a
                    href="/wallet"
              
                  >
                    <li style={{ color: "white", cursor: "pointer" }}  className=" my-3 mx-3">
                      <span className="sidebar-sub-toggle">
                        <i className="ti-wallet"></i> E-wallet
                      </span>
                    </li>
                  </a>

{userType== ""?
<>
<hr style={{color:"white", }}/>
<Link to='/profile'>
<li
                            style={{
                              marginLeft: "15px",
                              marginTop: "26px",
                              color: "white",
                            }}
                          >
                            <i style={{backgroundColor:"white", borderRadius:"50%", color:"black", textAlign:"center", fontWeight:"bolder", marginRight:"15px"}}  class=" fa-circle-1">1</i>
                            {/* <i className="fa-solid fa-1"></i> */}
                             Profile
                          </li>
                          </Link>


                          <Link to='/additional'>                                               
<li
                            style={{
                              marginLeft: "15px",
                              marginTop: "26px",
                              color: "white",
                            }}
                          >
                            <i style={{backgroundColor:"white", borderRadius:"50%", color:"black", textAlign:"center", fontWeight:"bolder", marginRight:"15px"}} className="fa py-1 ">2</i> Business Profile
                          </li>
                      
      </Link>       
      <Link to='/adddirector'>
<li
                            style={{
                              marginLeft: "15px",
                              marginTop: "26px",
                              marginBottom: "16px",
                              color: "white",
                            }}
                          >
                            <i style={{backgroundColor:"white", borderRadius:"50%", color:"black", textAlign:"center", fontWeight:"bolder", marginRight:"15px"}} className="fa  py-1">3</i> Director Profile
                          </li>
    </Link>

<hr style={{color:"white"}}/>         
</>:
<>
<li
                    onClick={() => {
                      

                      changeNavList(0,{"subscription":nav[0].subscription?false:true})
                     

                    }}
                  >
                    <a className="sidebar-sub-toggle">
                      <i className="fa fa-gift"></i> Subscription{" "}
                      <span className="sidebar-collapse-icon ti-angle-down"></span>
                    </a>
                  </li>
                  <li>
                   
                    { nav[0].subscription ? (
                      <>
                        <Link to="/subscription">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="fa fa-list"></i> Packages Showcase
                          </li>
                        </Link>
                        <Link to="/packages">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-info-alt"></i> Subscriptions
                          </li>
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}





                  </li>

                  {/* products List start*/}
                  <li
                    onClick={() => {
                      
                      changeNavList(0,{"product":nav[0].product?false:true})
                    }}
                  >
                    <a className="sidebar-sub-toggle">
                      <i className="fa-product-hunt fa"></i> Products
                      <span className="sidebar-collapse-icon ti-angle-down"></span>
                    </a>
                  </li>
                  <li>
                    { nav[0].product ? (
                      <>
                        <Link to="/productlist">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="fa fa-list"></i>Product List
                          </li>
                        </Link>
                        <Link to="/addproduct">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti ti-plus"></i> Add Product
                          </li>
                        </Link>
                        {/* <Link to="/bulkproduct">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti ti-layers-alt"></i> Matter Sheet
                          </li>
                        </Link> */}
                      </>
                    ) : (
                      <></>
                    )}
                  </li>

                  {/* products List End*/}

                  {/* Manage Leads start*/}
                  <li
                    onClick={() => {
                      
                      changeNavList(0,{"manageleads":nav[0].manageleads?false:true})
                    }}
                  >
                    <a className="sidebar-sub-toggle">
                      <i className="ti ti-magnet"></i> Manage Leads{" "}
                      <span className="sidebar-collapse-icon ti-angle-down"></span>
                    </a>
                  </li>
                  <li>
                    { nav[0].manageleads ? (
                      <>
                        <Link to="/buyleads">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti ti-bookmark-alt"></i>Product Buy Request
                          </li>
                        </Link>
                        <Link to="/sendrequest">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti ti-file"></i>Send Quote Request
                          </li>
                        </Link>
                        {/* <Link to="/productlead">
                          <li
                            style={{
                              marginLeft: '23px',
                              marginBottom: '6px',
                              color: 'white',
                            }}>
                            <i className="ti-wallet"></i>Direct Lead
                          </li>
                        </Link> */}
                      </>
                    ) : (
                      <></>
                    )}
                  </li>

                  {/* Manage Leads End*/}


                  {/* Khata Start */}
                  {/* <li
                    onClick={() => {
                      
                      changeNavList(0,{"khata":nav[0].khata?false:true})
                    }}
                  >
                    <a className="sidebar-sub-toggle">
                      <i className="ti ti-book"></i> Khata{" "}
                      <span className="sidebar-collapse-icon ti-angle-down"></span>
                    </a>
                  </li> */}
                  {/* <li>
                    { nav[0].khata ? (
                      <>
                        <Link to="/khataclient">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti ti-user"></i>Manage Clients
                          </li>
                        </Link>
                        <Link to="/khatamanageinvoice">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti ti-notepad"></i> Manage Invoices
                          </li>
                        </Link>
                        <Link to="/khatadeliverychallan">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti ti-comments"></i>Delivery Challans
                          </li>
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </li> */}
                  {/* Khata End */}
                


                  {/* Office Management End Hidden #1122*/}
                  {/* Office Management End */}
                  {/* New Business start*/}
                  {/* New Business start Hidden #1122*/}

                  {/* <li
                    onClick={() => {
                      
                      changeNavList(0,{"newbusiness":nav[0].newbusiness?false:true})
                    }}
                  >
                    <a className="sidebar-sub-toggle">
                      <i className="ti-layout-grid4-alt"></i>New Business Ideas{" "}
                      <span className="sidebar-collapse-icon ti-angle-down"></span>
                    </a>
                  </li>
                  <li>
                    { nav[0].newbusiness ? (
                      <>
                        <Link to="">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-wallet"></i>New Business Ideas
                          </li>
                        </Link>
                        <Link to="">
                          <li
                            style={{
                              marginLeft: "23px",
                              marginBottom: "6px",
                              color: "white",
                            }}
                          >
                            <i className="ti-info-alt"></i> Pre Feasibility
                            Report
                          </li>
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </li> */}

                  {/* New Business End Hidden #1122*/}
                  {/* New Business End*/}
                  

                  {/* Manual By Bazaid */}




</>

}
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <!-- /# sidebar --> */}

      <div className="header">
        <div className="container-fluid">
         



          {/* Edit by b */}
          <nav class="navbar navbar-expand-lg ">
  <div
                onClick={() => {
                  setDarw(!darw);
                }}
                className=" float-left"
              >
                <div className="hamburger sidebar-toggle">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </div>
          
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
     
   
              
     
    </ul>
    <div class="form-inline my-2 my-lg-0">
    <Link to="/buyer">
                  <button
                  className="btn btn-dark "
                    style={{
                  
                    
                     borderRadius:"20px",

                    }}
                  >
                    Switch to Buyer
                  </button>
                </Link>  

                <a href="/chat">
                  <div className="header-icon mt-3">
                      <i style={{ color: "black" }} className="ti-email"></i>
                      </div>

                      </a>
               
                <span
                  className="user-avatar text-uppercase mx-3 mt-3"
                  // style={{ marginRight: "25px" }}
                >
                  {userLogin.length > 0 ? <>{userLogin[0].name}</> : <></>}
                </span>
                <div
                  style={{ marginTop: "8px" }}
                  className=" dropdown dib dropdown show "
                >
                  <a
                    className="btn btn-dark dropdown-toggle "
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i style={{ size: "100px" }} className="ti-user "></i>
                  </a>

                  <div
                    className="dropdown-menu drop-down dropdown-profile  dropdown-menu-right user-avatar"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/user");
                      }}
                      className="dropdown-item"
                    >
                      <i className="mx-2 ti-power-off"></i>User Profile {userType.role}
                    </a>

                    {userType=="seller"?
                      <>
                      <div className="dropdown-divider"></div>
                    
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/profile");
                      }}
                      className="dropdown-item"
                    >
                      <i className="mx-2 ti-power-off"></i> Company Information
                    </a>
                    <div className="dropdown-divider"></div>


                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/additional");
                      }}
                      className="dropdown-item"
                    >
                      <i className="mx-2 ti-power-off"></i> Business Information
                    </a>
                    <div className="dropdown-divider"></div>
                    
                    
                    
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/companyimages");
                      }}
                      className="dropdown-item"
                    >
                      <i className="mx-2 ti-power-off"></i> Company Images
                    </a>
                    <div className="dropdown-divider"></div>

                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/director");
                      }}
                      className="dropdown-item"
                    >
                      <i className="mx-2 ti-power-off"></i> Director Profile
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/catalog");
                      }}
                      className="dropdown-item"
                    >
                      <i className="mx-2 ti-power-off"></i> Catalogs
                    </a>
                    <div className="dropdown-divider"></div></>:<></>

                    }


                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.removeItem("USER");
                        navigate("/login");
                      }}
                      className="dropdown-item"
                    >
                      <i className="mx-2 ti-power-off"></i> Log Out
                    </a>
                  </div>
                </div>
  </div>
  </div>
</nav>
          {/* Edit by b */}
        </div>
      </div>
    </>
  );
}

export default Nav;